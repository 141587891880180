import Swal from 'sweetalert2';


class SystemLoading {

  static show(message = "Loading") {

    if (typeof Swal === "undefined") {
      console.error("Couldn't show message, missing SWAL library");

      return false;
    }

    Swal.fire({
      title: message,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    return true;
  }

  static close(message = "", messageType = "success") {

    if (typeof Swal === "undefined") {
      console.error("Couldn't dismiss message, missing SWAL library");

      return false;
    }

    if (!message) {

      Swal.close();

    } else {

      Swal.fire({
        title: message,
        type: messageType,
        timer: 2000,
        showConfirmButton: false
      });

    }

  }

}

export default SystemLoading;
